//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Social from '@/components/modules/Social'
import InlineIcon from '~/assets/northbank_logo.svg?inline'

export default {
	layout: 'default',
	components: {
		Social,
		InlineIcon,
	},
	data() {
		return {}
	},
	props: ['alt', 'position'],
	computed: {},
	methods: {
		getPos() {
			if (this.position === 'bottom') {
				return true
			}
		},
	},
}
